import {Button, FileInput, Modal, Stack, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import FailureReportApi, {CreateFailureReport} from "../../../api/failure/report/FailureReportApi";
import {showNotification} from "@mantine/notifications";
import {DateTimePicker} from "@mantine/dates";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    closeAndUpdate: () => void;
}

const format = (dateString: string) => {
    if (dateString === null || dateString === undefined || dateString === "") {
        return undefined;
    }

    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
    const day = ('0' + date.getDate()).slice(-2);

    return `${year}-${month}-${day} 00:00:00`;
}

function CreateFailureReportDialog(props: Props) {
    const form = useForm<CreateFailureReport>({
        mode: 'uncontrolled',
        initialValues: {
            title: '',
            unit: '',
            dateTime: '',
            deviceNumber: '',
            storeVaccinesFrom: "",
            storeVaccinesPlace: "",
            storeVaccinesTo: "",
            file: null
        }
    });

    const onSubmit = (data: CreateFailureReport) => {
        const payload: CreateFailureReport = {
            ...data,

            storeVaccinesTo: format(data.storeVaccinesTo),
            storeVaccinesFrom: format(data.storeVaccinesFrom),
            dateTime: format(data.dateTime)
        }

        FailureReportApi.create(payload)
            .then(props.closeAndUpdate)
            .catch(() => showNotification({color: 'red', message: 'Nie udało się utworzyć raportu'}))
    }

    return (
        <Modal opened={props.isOpen} onClose={props.onClose} title="Nowy raport z interwencji">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack gap="sm">
                    <TextInput
                        label="Tytuł raportu"
                        required
                        placeholder="Tytuł raportu z interwencji, widoczny tylko dla administratora"
                        key={form.key('title')}
                        {...form.getInputProps('title')}
                    />

                    <TextInput
                        required
                        placeholder="Jednostka"
                        key={form.key('unit')}
                        {...form.getInputProps('unit')}
                    />

                    <DateTimePicker
                        required
                        valueFormat="DD-MM-YYYY"
                        label="Data"
                        key={form.key('dateTime')}
                        {...form.getInputProps('dateTime')}
                    />

                    <DateTimePicker
                        clearable
                        valueFormat="DD-MM-YYYY"
                        label="Przechowywanie szczepionek (Od)"
                        key={form.key('storeVaccinesFrom')}
                        {...form.getInputProps('storeVaccinesFrom')}
                    />

                    <DateTimePicker
                        clearable
                        valueFormat="DD-MM-YYYY"
                        label="Przechowywanie szczepionek (Do)"
                        key={form.key('storeVaccinesTo')}
                        {...form.getInputProps('storeVaccinesTo')}
                    />

                    <TextInput
                        label="Miejsce przechowywania szczepionek"
                        key={form.key('storeVaccinesPlace')}
                        {...form.getInputProps('storeVaccinesPlace')}
                    />

                    <FileInput
                        required
                        label="Plik raportu, który będzie wysłany do klienta"
                        description="Nazwa pliku nie ulegnie zmianie"
                        placeholder="Plik raportu"
                        key={form.key('file')}
                        {...form.getInputProps('file')}
                    />

                    <Button
                        fullWidth
                        type="submit"
                    >
                        Zapisz
                    </Button>
                </Stack>
            </form>
        </Modal>
    );
}

export default CreateFailureReportDialog;