// noinspection JSUnusedGlobalSymbols

import {User} from "../../user/UserApi";
import {PageRequest} from "../../../models/api/PageRequest";
import {SortedRequest} from "../../../models/api/SortedRequest";
import {Api} from "../../Api";
import {HttpClient} from "../../_http/HttpClient";
import Page from "../../../models/api/Page";
import {Device} from "../../device/DeviceApi";
import {DeviceMessage} from "../../device/message/DeviceMessageApi";
import FailureReport from "../../failure/report/FailureReport";

export type DateRange = { from: string; to: string };

export type ValidationSourceType = "record" | "date_report" | "section" | "composite_report";

export type ValidationSeverity = "low" | 'medium' | 'high';

export type ValidationCode = string;

export type Validation = {
    id: string;
    recordIndex?: number;
    sourceType: ValidationSourceType;
    sourceId: string;
    severity: ValidationSeverity;
    code: ValidationCode;
    date: [number, number, number] | undefined;
    originalHour: string | undefined;
}

export type Record = { temp1?: number; temp2?: number; };

export type DateReport = {
    id: string;
    date: string;
    records: Record[];
}

export type Section = {
    id: string;
    name?: string;
    order?: number;
    content: DateReport[];
}

export type CompositeReport = {
    id: string;
    validations: Validation[];
    unit?: string;
    filePath?: string;
    title?: string;
    description?: string;
    createdBy?: User;
    approvedBy?: User;
    createdAt?: string;
    approvedAt?: string;
    comments: string[];
    dateRange?: DateRange;
    sections?: Section[];
    devices: Device[];
    deviceMessages: DeviceMessage[];
}

export type SearchQueryBase = {
    unit?: string;
    createdBy?: string;
    approvedBy?: string;
    q?: string;
    createdAtMin?: string;
    createdAtMax?: string;

    /**
     * Does search results should include archived.
     * API will use default value: false.
     * @author Kacper Faber
     */
    includeArchived?: boolean;

    /**
     * Exclude one ID from results.
     * @author Kacper Faber
     */
    excludeId?: string;
}

export type GenerateForAllDevicesQuery = {
    /**
     * yyyy-mm-dd
     */
    dateMin: string;

    /**
     * yyyy-mm-dd
     */
    dateMax: string;

    /**
     * HH:mm
     */
    timeMin?: string;

    /**
     * HH:mm
     */
    timeMax?: string;
}

export type GenerateForSelectedDevicesQuery = { deviceIds: string[] } & GenerateForAllDevicesQuery;

export type SearchQuery = SearchQueryBase & PageRequest & SortedRequest;

export type Action = {
    action: 'delete' | 'send' | 'approve' | 'archive';
    selectedElements: string[];
}

export type EditModel = {
    id: string;
    unit?: string;
    title: string;
    description?: string;
    comments?: string[];
}

export type EditRecord = {
    /**
     * _original_time value, 11:00, 1:00 etc.
     * @author Kacper Faber
     */
    time: string;
    temp1?: number;
    temp2?: number;
}

export type EditRecordQuery = {
    compositeReportId: string;
    dateReportId: string;
    recordIndex: number;
}

export type GetRecordQuery = {
    compositeReportId: string;
    dateReportId: string;
    recordIndex: number;
}

export type GetRecordOrCreateQuery = {
    compositeReportId: string;
    dateReportId: string;
    hour: string;
}

export type AddOrEditRecordBody = {
    compositeReportId: string;
    dateReportId: string;
    hour: string;
    temp1: number | undefined;
    temp2: number | undefined;
}

export type AddOrEditRecordItem = {
    dateReportId: string;
    hour: string;
    temp1: number | undefined;
    temp2: number | undefined;
}

export type AddOrEditRecordsBody = {
    compositeReportId: string;
    items: AddOrEditRecordItem[];
}

export type AddCommentPayload = {
    comment: string;
    compositeReportId: string;
}

export type DeleteCommentTextPayload = {
    compositeReportId: string;
    comment: string;
}

export class CompositeReportApi {
    static search(query: SearchQuery) {
        const url = Api.getUrl("/composite/report");
        return HttpClient.get<Page<CompositeReport>>(url, { ...query });
    }

    static performAction(act: Action) {
        const url = Api.getUrl("/composite/report/action");
        return HttpClient.postAndExpectNoContent(url, {} , { ...act });
    }

    static generateForAllDevices(query: GenerateForAllDevicesQuery) {
        const url = Api.getUrl('/composite/report/generate_for_all_devices');
        return HttpClient.post(url, query, { });
    }

    static getFailureReports(id: string): Promise<FailureReport[]> {
        const url = Api.getUrl(`/composite/report/${id}/failure-reports`)
        return HttpClient.get(url, {});
    }

    static generateForSelectedDevices(query: GenerateForSelectedDevicesQuery) {
        const url = Api.getUrl('/composite/report/generate_for_devices');
        return HttpClient.post(url, { ...query }, { deviceIds: query.deviceIds });
    }

    static details(compositeReportId: string) {
        const url = Api.getUrl('/composite/report/details');
        return HttpClient.get<CompositeReport>(url, { compositeReportId });
    }

    static edit(editModel: EditModel) {
        const url = Api.getUrl('/composite/report/edit');
        return HttpClient.put(url, {}, {...editModel});
    }

    static editRecord(query: EditRecordQuery, editRecord: EditRecord) {
        const url = Api.getUrl('/composite/report/edit_record');
        return HttpClient.postAndExpectNoContent(url, { ...query }, { ...editRecord });
    }

    static getDateReport(dateReportId: string) {
        const url = Api.getUrl('/composite/report/date_report_details');
        return HttpClient.get<DateReport>(url, { dateReportId });
    }

    static getRecord(query: GetRecordQuery) {
        const url = Api.getUrl('/composite/report/get_record');
        return HttpClient.get<any>(url, { ...query });
    }

    static getRecordOrCreate(q: GetRecordOrCreateQuery): Promise<any> {
        const url = Api.getUrl('/composite/report/get_record_or_create');
        return HttpClient.get<any>(url, { ...q });
    }

    static addOrEditRecord(q: AddOrEditRecordBody) {
        const url = Api.getUrl('/composite/report/add_or_edit_record');
        return HttpClient.post<DateReport>(url, {}, { ...q });
    }

    static addComment(q: AddCommentPayload) {
        const url = Api.getUrl('/composite/report/add_comment');
        return HttpClient.put<CompositeReport>(url, {}, { ...q });
    }

    static deleteCommentText(q: DeleteCommentTextPayload) {
        const url = Api.getUrl('/composite/report/delete_comment_text');
        return HttpClient.deleteAndExpectNoContent(url, {}, { ...q });
    }

    static addOrEditRecords(body: AddOrEditRecordsBody) {
        const url = Api.getUrl('/composite/report/add_or_edit_records');
        return HttpClient.postAndExpectNoContent(url, {}, { ...body });
    }

    static getSuggestion(q: {compositeReportId: string}) {
        const url = Api.getUrl('/composite/report/suggest');
        return HttpClient.get<CompositeReport>(url, q);
    }
}
