import Page from "../../../models/api/Page";
import FailureReport from "./FailureReport";
import {Api} from "../../Api";
import {HttpClient} from "../../_http/HttpClient";
import {PageRequest} from "../../../models/api/PageRequest";
import {SortedRequest} from "../../../models/api/SortedRequest";

export type SearchQuery = {
    unit?: string;
} & PageRequest & SortedRequest;

export type CreateFailureReport = {
    title?: string;
    deviceNumber?: string;
    dateTime?: string;
    unit?: string;
    storeVaccinesFrom?: string;
    storeVaccinesTo?: string;
    storeVaccinesPlace?: string;
    file?: File;
}

class FailureReportApi {
    static search(query: SearchQuery): Promise<Page<FailureReport>> {
        const url = Api.getUrl('/failure/report/search');
        return HttpClient.get<Page<FailureReport>>(url, { ...query });
    }

    static deleteAll(ids: string[]): Promise<any> {
        const url = Api.getUrl('/failure/report/delete')
        return HttpClient.deleteAndExpectNoContent(url, {}, ids);
    }

    static create(payload: CreateFailureReport): Promise<FailureReport> {
        const url = Api.getUrl('/failure/report/create');
        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                if (key === 'file' && value instanceof File) {
                    formData.append(key, value);
                } else {
                    formData.append(key, String(value));
                }
            }
        });

        return HttpClient.postFormDataAndGetJson(url, {}, formData);
    }
}

export default FailureReportApi;