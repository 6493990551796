import React, {useEffect, useState} from 'react';
import {Anchor, Button, Modal, Stack} from "@mantine/core";
import {CompositeReportApi} from "../../../../api/composite/report/CompositeReportApi";
import {showNotification} from "@mantine/notifications";
import FailureReport from "../../../../api/failure/report/FailureReport";
import {Api} from "../../../../api/Api";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    compositeReportId: string;
}

function FailureReportsDialog(props: Props) {
    const [data, setData] = useState<FailureReport[]>();

    const reload = () => {
        CompositeReportApi.getFailureReports(props.compositeReportId)
            .then(setData)
            .catch(() => showNotification({message: 'Nie udało się pobrać danych'}))
    }

    useEffect(reload, [props.compositeReportId]);

    return (
        <Modal opened={props.isOpen} onClose={props.onClose}>
            <Stack>
                {data?.map(x =>
                    <Anchor onClick={() => {
                        window.location.href = Api.getUrl(`/failure/report/${x.id}/file`)
                    }}>
                        {x.title ?? "<Brak tytułu>"}
                    </Anchor>
                )}

                <Button onClick={props.onClose}>
                    Gotowe
                </Button>
            </Stack>
        </Modal>
    );
}

export default FailureReportsDialog;