import {
    AddCommentPayload,
    CompositeReport,
    CompositeReportApi
} from "../../../../../api/composite/report/CompositeReportApi";
import CardWrapper from "../CardWrapper";
import {useForm} from "@mantine/form";
import {Stack, TextInput} from "@mantine/core";
import {showNotification} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";
import {useState} from "react";

export type AddSimpleCommentCardAttrs = {
    compositeReport?: CompositeReport;
    reload: () => void;
}

function AddSimpleCommentCard(attrs: AddSimpleCommentCardAttrs) {
    const [loading, setLoading] = useState(false);

    const [startLoading, stopLoading] = [
        () => setLoading(true),
        () => setLoading(false)
    ];

    const form = useForm({
        mode: 'uncontrolled',
        initialValues: { comment: ''},
        validate: { comment: (e: string | any[]) => e.length < 2 ? 'Przynajmniej dwa znaki' : null }
    });

    const doSubmitSuccess = () => {
        showNotification({
            title: 'Dodano komentarz!',
            message: 'Chcesz dodać następny?',
            color: 'green',
            icon: <IconCheck/>
        });

        form.setValues({comment: ''});
    }

    const doSubmitError = () => {
        showNotification({
            title: 'Nie udało się zapisać komentarza!',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem?',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doSubmit = () => {
        if (!attrs.compositeReport) return;
        const {comment} = form.getValues();

        const payload: AddCommentPayload = {
            compositeReportId: attrs.compositeReport.id,
            comment
        }

        startLoading();

        CompositeReportApi.addComment(payload)
            .then(doSubmitSuccess)
            .then(attrs.reload)
            .catch(doSubmitError)
            .finally(stopLoading);
    }

    return (
        <CardWrapper loading={attrs.compositeReport === undefined || loading} title="Dodaj komentarz">
            <form onSubmit={form.onSubmit(doSubmit)}>
                <Stack pos="relative">
                    <TextInput
                        placeholder="Komentarz"
                        variant="filled"
                        key={form.key('comment')}
                        { ...form.getInputProps('comment') }
                    />
                </Stack>
            </form>
        </CardWrapper>
    );
}

export default AddSimpleCommentCard;