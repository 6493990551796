import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {useEffect, useState} from "react";
import FailureReport from "../../../api/failure/report/FailureReport";
import FailureReportApi, {SearchQuery} from "../../../api/failure/report/FailureReportApi";
import {showNotification} from "@mantine/notifications";
import Page from "../../../models/api/Page";
import {DataTable, DataTableColumn, useDataTableColumns} from "mantine-datatable";
import DateTimeView from "../../../components/date/DateTimeView";
import CreateFailureReportDialog from "./CreateFailureReportDialog";
import {Anchor, Button, Flex, Group, TextInput} from "@mantine/core";
import {Api} from "../../../api/Api";

const key = 'FailureReport-StoreKey';


function FailureReportPage() {
    const [query, setQuery] = useState<SearchQuery>({limit: 50, page: 0});
    const [data, setData] = useState<Page<FailureReport>>();
    const [selectedRecords, setSelectedRecords] = useState<FailureReport[]>([]);
    const [createDialog, setCreateDialog] = useState(false);

    const columns: DataTableColumn<FailureReport>[] = [
        {
            accessor: 'id',
            title: '_id',
            toggleable: true,
            defaultToggle: false,
            sortable: true
        },
        {
            accessor: 'unit',
            title: '#',
            toggleable: true,
            sortable: true,
            draggable: true
        },
        {
            accessor: 'title',
            title: 'Nazwa raportu'
        },
        {
            accessor: 'dateTime',
            title: 'Data',
            toggleable: true,
            sortable: true,
            draggable: true,
            render: r => <DateTimeView dateTime={r.dateTime}/>
        },
        {
            accessor: 'storeVaccinesFrom',
            title: 'Przechowywanie szczepionek (Od)',
            render: r => <DateTimeView dateTime={r.storeVaccinesFrom}/>,
            toggleable: true,
            sortable: true,
            draggable: true
        },
        {
            accessor: 'storeVaccinesTo',
            title: 'Przechowywanie szczepionek (Do)',
            render: r => <DateTimeView dateTime={r.storeVaccinesTo}/>,
            toggleable: true,
            sortable: true,
            draggable: true
        },
        {
            accessor: 'storeVaccinesPlace',
            title: 'Miejsce przechowywania szczepionek',
            toggleable: true,
            sortable: true,
            draggable: true
        },
        {
            accessor: 'filePath',
            title: 'Plik',
            toggleable: true,
            sortable: false,
            draggable: true,
            render: (r) => (
                <Anchor variant="transparent" onClick={() => {
                    window.location.href = Api.getUrl(`/failure/report/${r.id}/file`)
                }}>
                    Pobierz
                </Anchor>
            )
        }
    ]

    const {effectiveColumns} = useDataTableColumns({key, columns})

    const reload = () => {
        let searchQuery = {
            ...query,
            unit: query.unit === "" ? undefined : query.unit
        };
        FailureReportApi.search(searchQuery)
            .then(setData)
            .catch(() => showNotification({message: 'Nie udało się pobrać danych'}))
    }

    const deleteSelected = () => {
        if (selectedRecords.length === 0) {
            return;
        }

        FailureReportApi.deleteAll(selectedRecords.map(it => it.id))
            .then(() => showNotification({message: 'Usunięto zaznaczone elementy'}))
            .then(reload)
            .then(() => setSelectedRecords([]))
            .catch(() => showNotification({message: 'Nie udało się usunąć zaznaczonych elementów', color: 'red'}))
    }

    useEffect(reload, [query]);

    return (
        <>
            <CreateFailureReportDialog
                isOpen={createDialog}
                onClose={() => setCreateDialog(false)}
                closeAndUpdate={() => {
                    setCreateDialog(false);
                    reload();
                }}
            />

            <PageHeader
                title="Raporty z interwencji"
                pageTitle="Raporty z interwencji"
                url="/failure/report"
                action={
                    <Group>
                        <Button onClick={deleteSelected} color="red">
                            Usuń zaznaczone
                        </Button>

                        <Button onClick={() => setCreateDialog(true)}>
                            Utwórz
                        </Button>
                    </Group>
                }
            />

            <PageContent>
                <Flex direction="row" align="center" gap={'md'}>
                    <TextInput
                        placeholder="Jednostka"
                        onChange={ev => {
                            query.unit = ev.target.value
                        }}
                    />

                    <Button onClick={() => reload()}>Szukaj</Button>
                </Flex>

                <DataTable
                    mt="xl"
                    striped
                    height="60vh"
                    withColumnBorders
                    styles={{header: {background: 'white'}}}
                    highlightOnHover
                    loaderBackgroundBlur={1}
                    backgroundColor="transparent"
                    noRecordsText="Pusto..."
                    columns={effectiveColumns}
                    records={data?.content}
                    selectedRecords={selectedRecords}
                    onSelectedRecordsChange={setSelectedRecords}
                    minHeight={150}
                    style={{marginBottom: '50px'}}
                    storeColumnsKey={key}
                />
            </PageContent>
        </>
    );
}

export default FailureReportPage;